<template>
	<div>
		<a-button type="link" @click="showAct"  icon="setting">设置</a-button>
		
		<a-modal
			  width="480px"
		      title="设置组件"
		      :visible="showEdit"
			  :footer="null"
		      @cancel="handleCancel"
		    >
			<div class="mt12 flex alcenter">
			   <div style="width: 100px;">距离上组件</div> <a-input-number v-model="mydatas.style.mt" :min="0" :max="50" />px
			</div>
			
		</a-modal>
	</div>
</template>

<script>
	export default{
		props:{
			value:{
				type:Object,
				default:function(){
					return new Object;
				}
			},
		},
		watch:{
			mydatas:{
				handler(newName, oldName){
					this.$emit('input',this.mydatas);
				},
				immediate: false,
				deep: true
			},
			value:{
				handler(newName, oldName){
					if(this.value != this.mydatas){
						this.mydatas = this.value;
					}
				},
				immediate: false,
				deep: true
			}
		},
		data(){
			return {
				mydatas:null,
				showEdit:false,
			}
		},
		created(){
			this.mydatas = this.value;
		},
		methods:{
			showAct(){
				this.showEdit = true;
			},
			handleCancel(){
				this.showEdit = false;
			}
		}
	}
</script>

<style>
	
</style>