export const footers = [
	{name:'分类',model:'category',icon:'icontabbar05'},
	{name:'发现',model:'look',icon:'icontabbar06'},
	{name:'购物车',model:'cart',icon:'icontabbar07'},
	{name:'买单',model:'buy',icon:'icontabbar02'},
];

//打开小程序 就是 miniapp:wx*** 电话就是tel:****
export const plugins = [
	{
		name:'搜索框',
		key:'search',
		style:{
			mt:0,//距离顶部
		}
	},
	{
		name:'轮播图片',
		key:'banner',
		style:{
			mt:16,//距离顶部
			h:150,//高度
			r:8,//圆角
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'图片',
		key:'pics',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			space:0,//间距 当图片大于1的时候  最多4张图片
		},
		datas:[
			{pic:'', url:''}
		],
	},
	{
		name:'视频',
		key:'video',
		style:{
			mt:16,//距离顶部
			h:150,//高度 个体最大高度
			url:'',
		},
		datas:[
			
		],
	},
	{
		name:'图标导航',
		key:'icon',
		style:{
			type:0,//样式1 样式2  样式3
			mt:16,//距离顶部
			isBg:1,
			space:10,
		},
		datas:[
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
			{pic:'', name:'图标',url:''},
		],
	},
	{
		name:'秒杀',
		key:'miaosha',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'秒杀推荐',
		},
		datas:[ //模拟数据
			{cover_img:'',name:'',price:100,original_price:120,flash_sale_djs:86400,flash_sale_progress:10,fictitious_take_count:10}
		],
	},
	{
		name:'预售',
		key:'booking',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'预售推荐',
		},
		datas:[
			{cover_img:'',name:'',yu_price:100,original_price:120,yu_days:7,fictitious_take_count:10}
		],
	},
	{
		name:'拼团',
		key:'pintuan',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'我要拼团',
		},
		datas:[
			{cover_img:'',name:'',pin_tuan_price:100,original_price:120,group_num:3,fictitious_take_count:10}
		],
	},
	{
		name:'囤货',
		key:'store',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'我要囤货',
		},
		datas:[
			{cover_img:'',name:'',gift_total_price:100},
			{cover_img:'',name:'',gift_total_price:200}
		],
	},
	{
		name:'商品推荐',
		key:'product',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'商品推荐',
			num:8,
			category:0,
		},
		datas:[
			{cover_img:'',name:'',vip_price:100,original_price:120,fictitious_take_count:10},
			{cover_img:'',name:'',vip_price:100,original_price:120,fictitious_take_count:10},
			{cover_img:'',name:'',vip_price:100,original_price:120,fictitious_take_count:10},
			{cover_img:'',name:'',vip_price:100,original_price:120,fictitious_take_count:10},
		],
	},
	{
		name:'优惠券',
		key:'coupon',
		style:{
			mt:16,//距离顶部
			icon:'',
			title:'优惠券',
		},
		datas:[
			{name:'10元优惠券',money_limit:100,valid_bg_time:'2021.05.16',valid_end_time:'2030.05.16',coupon_money:10},
		],
	},
];