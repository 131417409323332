<template>
	<div class="miaosha-box pd16_15">
		<img class="miaosha-img01" src="../../../../../assets/image/mall_img_miaosha_bl@3x.png" />
		<img class="miaosha-img02" src="../../../../../assets/image/mall_img_miaosha_tr@3x.png" />
		<div class="flex alcenter space">
		
			<div class="flex alcenter">
				<img style="width: 20px; height: 20px;" class="mr10" v-if="datas.style.icon && datas.style.icon !='' " :src="datas.style.icon" />
				<img v-else style="width: 20px; height: 20px;" class="mr10" src="../../../../../assets/image/mall_home_miaosha@3x.png" />
				<span class="ft16 ftw600 cl-main">{{datas.style.title ? datas.style.title:'秒杀推荐'}}</span>
			</div>
			<div>
				<span class="ft12 cl-main">查看全部</span>
				<span class="iconfont iconarrowright_black ft12 cl-main ml5"></span>
			</div>
		</div>
		<div class="miaosha-product-box mt16">
			<a-spin :spinning="loading">
				<div v-if="calldata == null" class="plugin-empty" style="height: 120px;line-height: 120px;">暂无数据</div> 
				<div v-else class="flex">
					<div class="product-img-box small">
						<img class="product-img" style="object-fit: cover" :src="calldata.cover_img" />
						<div class="product-tag flex alcenter">
							<time-countdown :t="calldata.flash_sale_djs" size="ft12 cl-w" type="text"></time-countdown>
						</div>
					</div>
					<div class="product-r small pd10">
						<div class="ft14 ftw600 cl-main text-over">{{calldata.name}}</div>
						<div class="mt12">
							<span class="ft12 cl-price">秒杀价¥</span>
							<span class="ft16 ftw600 cl-price">{{calldata.price}}</span>
							<span class="ft12 cl-notice text-line ml5">¥{{calldata.original_price}}</span>
						</div>
						<div class="mt10 miaosha-pross">
							<div class="value" :style="{width:calldata.flash_sale_progress + '%'}"></div>
						</div>
						<div class="mt8 flex alcenter space">
							<span class="ft12 cl-notice">已售 {{calldata.fictitious_take_count}}</span>
							<span class="btn-buy" :style="{background:mainColor}">抢购</span>
						</div>
					</div>
				</div>
			</a-spin>
		</div>
	</div>
</template>

<script>
	import timeCountdown from '../../djs.vue';
	export default{
		props:{
			mainColor:{
				type:String,
				default:'',
			},
			datas:{
				type:Object,
				default:function(){
					return new Object;
				}
			}
		},
		components:{
			timeCountdown
		},
		computed:{
			calldata(){
				return this.datas.datas[0];
			}
		},
		data(){
			return {
				loading:false,
				
			}
		},
		created(){
		},
		methods:{
			
		}
	}
</script>

<style>
	.miaosha-box{
		background: #FFEB82;
		border-radius: 8px;
		line-height: 1;
		position: relative;
		overflow: hidden;
	}
	.miaosha-img01{
		width: 90px;
		height: 69px;
		position: absolute;
		left: 0;
		bottom: 0;
	}
	.miaosha-img02{
		position: absolute;
		right: 0;
		top: 0;
		width: 50px;
		height: 50px;
	}
	.miaosha-box .miaosha-product-box{
		width: 100%;
		height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
		border-radius: 8px;
		overflow: hidden;
		position: relative;
		z-index: 2;
	}
	.miaosha-pross{
		width: 100%;
		height: 8px;
		background: #EDEDED;
		border-radius: 4px;
		position: relative;
	}
	.miaosha-pross .value{
		width: 30%;
		height: 8px;
		background: #00BA26;
		border-radius: 4px;
		position: absolute;
		left: 0;
		top: 0;
	}
</style>